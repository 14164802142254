html {
  scroll-behavior: smooth;
  transition: 0.2s;
}

::-webkit-scrollbar {
  width: 0.3em;
  background-color: black;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(32, 32, 32);
  outline: 1px solid rgb(0, 0, 0);
}

.sectionTitle {
  font-family: 'Merriweather';
  font-weight: 400;
  font-size: 2.5rem;
  text-align: center;
  /* margin-top: 100px; */
}

@media (max-width: 1200px) {
  .sectionTitle {
    font-size: 1.8rem;
  }

}